import React from 'react';

import Image from '../Image';

import kaustLogo from '../../images/kaust-logo.svg';

const AuthorPicture = ({ picture }) => (
  <div className="teaser__thumbnail">
    { picture && picture.localFiles
      ? (
        <Image image={ picture.localFiles[0] } />
      )
      : (
        <img
          alt="Kaust logo"
          className="teaser__thumbnail__default"
          src={ kaustLogo }
        />
      ) }

  </div>
);

export default AuthorPicture;
