/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useRef } from 'react';
import { graphql, Link } from 'gatsby';
import moment from 'moment';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import PostThumbnail from '../components/posts/PostThumbnail';
import Author from '../components/authors/Author';

const ListPage = ({ data }) => {
  const {
    data: {
      edges: posts,
    },
  } = data;

  const [ selectedAuthor, setSelectedAuthor ] = useState(false);
  const [ selectedPost, setSelectedPost ] = useState(false);
  const selectedAuthorRef = useRef();
  const selectedPostRef = useRef();

  const handleAuthorClick = (e, author) => {
    e.preventDefault();
    author.data.Posts = author.data.Posts?.sort((post1, post2) => moment(post2.data.Date, 'DD.MM.YYYY') - moment(post1.data.Date, 'DD.MM.YYYY'));
    setSelectedAuthor(author);
    setSelectedPost(false);
    selectedAuthorRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const handlePostClick = (e, post) => {
    e.preventDefault();
    setSelectedPost(post);
    selectedPostRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <Layout pageClass="page--full">
      <SEO
        keywords={ [] }
        title="Index"
      />

      <div className="authors-list">

        <div className="authors-list__list__wrapper">
          <div className="authors-list__list">
            { posts.map(({ node: author }, key) => (
              <div key={ `item-${key * key}` } className="authors-list__list__item">
                <button type="button" onMouseOver={ e => handleAuthorClick(e, author) } onClick={ e => handleAuthorClick(e, author) }>
                  { author.data.Name }
                </button>
              </div>
            )) }
          </div>
        </div>

        <div className="authors-list__content" ref={ selectedAuthorRef }>

          { selectedAuthor && (
            <div className="authors-list__selected-author">
              <Author author={ selectedAuthor } />
            </div>
          ) }

          { selectedAuthor && (

            <div className="authors-list__selected-posts" ref={ selectedPostRef }>

              { selectedAuthor && (
                <div className="authors-list__selected-posts__list">
                  { selectedAuthor.data.Posts && selectedAuthor.data.Posts.map((post, keyPost) => (
                    <div key={ `item-${keyPost * keyPost}` } className="authors-list__selected-posts__list__item">
                      <button type="button" onMouseOver={ e => handlePostClick(e, post) } onClick={ e => handlePostClick(e, post) }>
                        { post.data.Date }
                      </button>
                    </div>
                  )) }
                </div>
              ) }

              { selectedPost && (
                <div className="authors-list__selected-posts__thumbnail">
                  <Link to={ selectedPost.fields.slug }>
                    <PostThumbnail thumbnail={ selectedPost.data.Thumbnail } />
                  </Link>
                </div>
              ) }

            </div>
          ) }

        </div>
      </div>

    </Layout>
  );
};

export default ListPage;

export const query = graphql`
  query {
    data: allAirtable(filter: {table: {eq: "Author"}, data: {Disable_page: {ne: true}}}, sort: {fields: data___Name_sorting, order: ASC}) {
     edges {
       node {
         data {
           Name
           Passion
           Specialism
           Picture {
              localFiles {
                extension
                url
                childImageSharp {
                  fluid(maxHeight: 237) {
                    ...GatsbyImageSharpFluid
                    ...GatsbyImageSharpFluidLimitPresentationSize
                  }
                }
              }
            }
           Posts {
             data {
               Title
               Date(formatString: "DD.MM.YYYY")
               Thumbnail {
                 localFiles {
                   extension
                   url
                   childImageSharp {
                     fluid(maxHeight: 237) {
                       ...GatsbyImageSharpFluid
                       ...GatsbyImageSharpFluidLimitPresentationSize
                     }
                   }
                 }
               }
             }
             fields {
               slug
             }
           }
         }
         fields {
           slug
         }
       }
     }
   }
  }
`;
