import React from 'react';

import { Link } from 'gatsby';
import AuthorPicture from './AuthorPicture';

const Author = ({ author }) => {
  const AuthorFields = (
    <>
      <div className="teaser__metadata">
        <div className="teaser__metadata__title">{ author.data.Name }</div>
        <div>{ author.data.Specialism }</div>
        <div className="teaser__metadata__passion">{ author.data.Passion }</div>
      </div>

      <AuthorPicture picture={ author.data.Picture } />
    </>
  );

  if (author.data.Disable_page) {
    return (
      <div className="teaser">
        { AuthorFields }
      </div>
    );
  }
  return (
    <Link to={ author.fields.slug } className="teaser">
      { AuthorFields }
    </Link>
  );
};

export default Author;
